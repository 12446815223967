import * as React from 'react';
import {useRecordContext, useTranslate} from 'react-admin';

import CardWithIcon from './CardWithIcon';
import {MoneyOutlined} from "@mui/icons-material";
import {Grid} from "@mui/material";

interface Props {
    value?: string|number;
    source?:string
}

const DashboardCard = (props: Props) => {
    const { source } = props;
    const translate = useTranslate();
    const record = useRecordContext();
    const src=source||''
    return (
        <Grid item>
            <CardWithIcon
                to="/commands"
                icon={MoneyOutlined}
                title={translate(`pos.dashboard.${src}`)}
                subtitle={record[src]}
            />
        </Grid>
    );
};

export default DashboardCard;
