import {
    Datagrid,
    List,
    MenuItemLink,
    ReferenceField,
    SelectField,
    TextField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import show from './show'
import {
    DnsOutlined,
    HowToRegOutlined,
    MotionPhotosAutoOutlined,
    PaymentOutlined,
    PeopleOutline,
    PersonOutline,
    SettingsSuggestOutlined
} from "@mui/icons-material";
import {ActorType} from "../../utils/types";
import {FilAmountField} from "../../widget";
import {LAMenuItemProps} from "../../types";
import * as React from "react";

const AccountIcon=(x:any)=>{
    const record=useRecordContext(x.source)
    const acc_type=Number(record.account_type)
    switch (acc_type){
        case ActorType.account:
            return <PersonOutline/>;
        case ActorType.actor:
            return <MotionPhotosAutoOutlined/>;
        case ActorType.miner:
            return <DnsOutlined/>
        case ActorType.sys:
            return <SettingsSuggestOutlined/>
        case ActorType.multisig:
            return <PeopleOutline/>
        case ActorType.paym:
            return <PaymentOutlined/>
        case ActorType.registry:
            return <HowToRegOutlined/>
        default:
            return null

    }

}
 const list = () => (
    <List>
        <Datagrid rowClick="show">
            {/*<AccountBalanceIcon/>*/}
            {/*<PersonOutline/>*/}
            {/*<PeopleOutline/>*/}
            {/*<DnsOutlined/>*/}
            {/*<MotionPhotosAutoOutlined/>*/}
            {/*<SettingsSuggestOutlined/>*/}
            {/*<PaymentOutlined/>*/}
            {/*<HowToRegOutlined/>*/}
            <AccountIcon/>
            <TextField source="id" />
            <ReferenceField source="tenant_id" reference="Tenant" link="show" ><TextField source="name"/> </ReferenceField>

            <TextField source="address" />

            <ReferenceField source="Owner" reference="Account" link="show" ><TextField source="name"/> </ReferenceField>

            <ReferenceField source="Worker" reference="Account" link="show" ><TextField source="name"/> </ReferenceField>
            <ReferenceField source="Beneficiary" reference="Account" link="show" ><TextField source="name"/> </ReferenceField>

            {/*<ReferenceArrayField source="ControlAddresses" reference="Account"/>*/}
            <TextField source="account_type"/>
            <TextField source="name" />
            <TextField source="descr" />
            <TextField source="robust_addr" />
            <TextField source="sealProof" />
            <SelectField source="account_type" choices={[
                { id: 0, name: 'Miner' },
                { id: 1, name: 'Account 1' },
                { id: 2, name: 'Account 2' },
                { id: 3, name: 'Account 3' },
            ]} />
            <FilAmountField source="Balance"/>


        </Datagrid>
    </List>
);


const LaMenuItem = (props: LAMenuItemProps ) => {
    const translate=useTranslate()
    return (
        <MenuItemLink
            to="/Account"
            state={{_scrollToTop: true}}
            primaryText={translate(`resources.blockchain.accounts`, {
                smart_count: 2,
            })}
            leftIcon={<AccountBalanceIcon/>}
            dense={false}
        />
    );
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {list,show,icon:AccountBalanceIcon,LaMenuItem}